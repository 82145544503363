import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import {
  Icon,
  IconsNames,
  InfoCard,
  LinkArrow,
  SaleOfferCard,
  StickyCounter,
} from 'components/atoms';
import { MainForm, Hits } from 'components/molecules';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { PATHS } from 'router/config';

const CoursesOnSale = memo(() => (
  <SecondaryTitle>
    Выбрать курс и получить скидку
    <IconArrow>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.255063 9.75927C0.0278449 9.51807 0.0391813 9.13834 0.280384 8.91112L4.43216 5.00005L0.280384 1.08898C0.039182 0.861765 0.0278457 0.482035 0.255064 0.240833C0.482282 -0.000369469 0.862012 -0.0117058 1.10321 0.215513L5.71861 4.56332C5.83896 4.67669 5.90719 4.83471 5.90719 5.00005C5.90719 5.1654 5.83896 5.32341 5.71861 5.43679L1.10321 9.78459C0.862011 10.0118 0.482281 10.0005 0.255063 9.75927Z"
          fill="#2E2E2E"
        />
      </svg>
    </IconArrow>
  </SecondaryTitle>
));

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>
            Исполняем <br /> желания!
          </Title>
          <Warning>Получи гарантированную скидку 15 000 ₽</Warning>

          <Subtitle>
            и участвуй в розыгрыше бесплатного обучения и других подарков к
            Новому году.
          </Subtitle>
        </MainInfoWrapper>

        <SaleCourses>
          <GiveawayRules>
            <GiveawayTitle>Условия розыгрыша</GiveawayTitle>
            <GiveawayInfo type="1">
              <li>
                Оставь заявку на странице интересующего тебя курса или на
                главной, если не знаешь, какое направление выбрать
              </li>
              <li>Гарантировано получи скидку 15 000 ₽ на обучение</li>
              <li>Регистрируйся по ссылке в боте</li>
              <li>
                Участвуй в розыгрыше и выигрывай еще большие скидки и бесплатное
                обучение.
              </li>
            </GiveawayInfo>

            <GiveawayAdditional>
              <StyledGiveawayIcon name={IconsNames.design_cover7} />
              Если ты новый студент и уже приступил к обучению на курсе со
              скидкой 15 000 ₽, а потом выиграл скидку больше или бесплатное
              обучение - мы вернем уже оплаченную разницу.
            </GiveawayAdditional>
          </GiveawayRules>

          <SaleOfferCard
            path={PATHS.courses}
            title={<CoursesOnSale />}
            infoText=""
            image={IconsNames.common_cover}
          >
            <GiveawayText>
              Воспользоваться скидками можно на курсах, которые стартуют в
              декабре 2024 и весь 2025 год
            </GiveawayText>
          </SaleOfferCard>

          <AdditionalOffer>
            <SecondaryTitle>Подарки</SecondaryTitle>
            <DiscountBlockText>
              <li>3 сертификата на бесплатное обучение</li>
              <li>5 сертификатов со скидкой 50%</li>
              {/* <li> 10 сертификатов на скидку 20 000 ₽</li> */}
              <li>Гарантированная скидка 15 000 ₽</li>
            </DiscountBlockText>
            <IconStyled name={IconsNames.design_cover2} />
          </AdditionalOffer>
        </SaleCourses>

        <HitCourses>
          <HitCoursesHead>
            <SecondaryTitle>Популярные курсы</SecondaryTitle>
            <LinkArrow title="Смотреть все" link={`${PATHS.courses}`} />
          </HitCoursesHead>

          <Hits />
        </HitCourses>
      </Content>
      <FormWrapper ref={formRef}>
        <MainForm />
        <StyledInfoCard />
      </FormWrapper>

      <StickyCounter formRef={formRef} />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 0 0 80px;
  }

  ${desktop} {
    margin: 32px 0 96px;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 68px;
    line-height: 80px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 16px;
  line-height: 22px;
  width: 72%;

  ${tablet} {
    font-size: 24px;
    line-height: 32px;
  }
  ${desktop} {
    width: unset;
  }
`;

const Warning = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 0 8px 8px 0;
  background-color: ${ColorService.YELLOW};
  font-family: ${getFontFamily('bold')};
  text-transform: uppercase;
  color: ${ColorService.BLACK};
  width: 72%;

  ${tablet} {
    font-size: 24px;
    line-height: 34px;
    padding: 16px;
    border-radius: 0 16px 16px 0;
    width: max-content;
  }

  ${desktop} {
    width: unset;
  }
`;

const SecondaryTitle = styled.h3`
  width: 60%;
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;
  z-index: 1;

  ${tablet} {
    width: 100%;
    font-size: 32px;
    line-height: 44px;
  }
`;

const IconArrow = styled.div`
  cursor: pointer;
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  top: 4px;

  svg {
    position: absolute;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${desktop} {
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 54px;
  }
`;

const SaleCourses = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    margin-bottom: 80px;
    gap: 20px;
  }
`;

const HitCourses = styled.div``;

const HitCoursesHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  display: none;

  ${desktop} {
    display: unset;
  }
`;

const AdditionalOffer = styled.div`
  position: relative;
  background-color: ${ColorService.WHITE};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  width: 100%;
  height: 420px;
  transition: 0.6s;
  overflow: hidden;

  ${tablet} {
    height: 332px;
  }
`;

const IconStyled = styled(Icon)`
  height: 78%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  border-radius: 24px;
  right: -40px;
  top: 0;

  ${tablet} {
    height: 100%;
    bottom: 0;
    right: -12px;
  }
`;

const DiscountBlockText = styled.ul`
  color: ${ColorService.BLACK};
  font-size: 18px;
  line-height: 24px;
  z-index: 1;
  margin-top: 32px;
  padding-left: 20px;
  list-style: initial;
  width: 58%;

  li:not(:last-child) {
    margin-bottom: 14px;
  }

  ${tablet} {
    margin-top: 40px;
    width: 100%;
  }
`;

const GiveawayText = styled.p`
  color: ${ColorService.GRAY};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  z-index: 1;
  max-width: 390px;
  margin-top: auto;
`;

const GiveawayRules = styled.div`
  background-color: ${ColorService.WHITE};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  width: 100%;
`;

const GiveawayTitle = styled.h3`
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const GiveawayInfo = styled.ol`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 32px;
  padding-left: 22px;

  ${tablet} {
    margin-bottom: 24px;
  }

  li:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const GiveawayAdditional = styled.div`
  padding: 16px 20px;
  border-radius: 16px;
  background-color: ${ColorService.GRAY_DARK};
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const StyledGiveawayIcon = styled(Icon)`
  display: none;
  flex-shrink: 0;

  ${tablet} {
    display: block;
    width: 65px;
    height: 69px;
  }
`;
