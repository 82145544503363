import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { getFontFamily, ColorService, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms';

interface ILink {
  fontSizeLink?: string;
  lineHeightLink?: string;
  isBoldLink?: boolean;
}

interface ISubtitle {
  fontSizeSubtitle?: string;
  lineHeightSubtitle?: string;
}
type IFonts = ISubtitle & ILink;

export interface ICheckMarkBlock {
  linkTitle: string;
  subtitle?: string;
  link: string;
  fontsStyle?: IFonts;
  iconName?: IconsNames;
}

export const ContactOption = memo(
  ({ linkTitle, link, subtitle, fontsStyle, iconName }: ICheckMarkBlock) => (
    <Box>
      <Row>
        {iconName && (
          <BoxIcon>
            <Icon name={iconName} />
          </BoxIcon>
        )}
        <Title
          isBoldLink={fontsStyle?.isBoldLink}
          fontSizeLink={fontsStyle?.fontSizeLink}
          lineHeightLink={fontsStyle?.lineHeightLink}
        >
          <Link to={link}>{linkTitle}</Link>
        </Title>
      </Row>
      <Row>
        {iconName && <BoxIcon />}
        {subtitle && (
          <Subtitle
            fontSizeSubtitle={fontsStyle?.fontSizeSubtitle}
            lineHeightSubtitle={fontsStyle?.lineHeightSubtitle}
          >
            {subtitle}
          </Subtitle>
        )}
      </Row>
    </Box>
  ),
);

const Box = styled.div``;

const Title = styled.div<ILink>`
  ${({ fontSizeLink, lineHeightLink, isBoldLink }) => css`
    a {
      color: ${ColorService.BLACK};
      font-family: ${getFontFamily(isBoldLink ? 'medium' : undefined)};
      font-size: ${fontSizeLink ? '18px' : '14px'};
      line-height: ${lineHeightLink ? '24px' : '21px'};

      &:hover {
        transition: 0.6s;
        color: ${ColorService.MUSTARD} !important;
      }
    }

    ${tablet} {
      a {
        font-size: ${fontSizeLink || '14px'};
        line-height: ${lineHeightLink || '21px'};
      }
    }
  `};
`;

const Subtitle = styled.p<ISubtitle>`
  ${({ fontSizeSubtitle, lineHeightSubtitle }) => css`
    color: ${ColorService.GRAY};
    font-family: ${getFontFamily()};
    font-size: ${fontSizeSubtitle ? '14px' : '11px'};
    line-height: ${lineHeightSubtitle ? '21px' : '16px'};

    ${tablet} {
      font-size: ${fontSizeSubtitle || '11px'};
      line-height: ${lineHeightSubtitle || '16px'};
    }
  `};
`;

const BoxIcon = styled.div`
  position: relative;
  height: 20px;
  width: 16px;
  margin-right: 11px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${tablet} {
    justify-content: flex-start;
  }
`;
