import React, { memo } from 'react';

import styled from 'styled-components';

import { Icon, IconsNames } from 'components/atoms';
import { desktop, laptop_small, tablet } from 'services';

export const CourseFeatures = memo(() => (
  <FeaturesList>
    <FeatureItem>
      <Icon name={IconsNames.checkMark} width={24} height={24} />
      Программа 2024 года
    </FeatureItem>
    <FeatureItem>
      <Icon name={IconsNames.checkMark} width={24} height={24} />
      Средний размер группы - 18 чел.
    </FeatureItem>
    <FeatureItem>
      <Icon name={IconsNames.checkMark} width={24} height={24} />
      Live формат
    </FeatureItem>
    <FeatureItem>
      <Icon name={IconsNames.checkMark} width={24} height={24} />
      Помогаем с поиском работы
    </FeatureItem>
  </FeaturesList>
));

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-row-gap: 16px;
  justify-content: space-between;
  margin-bottom: 56px;

  ${tablet} {
    grid-template-columns: 36% 50%;
    margin-bottom: 80px;
  }

  ${desktop} {
    grid-template-columns: 1fr;
    margin-bottom: 64px;
  }

  ${laptop_small} {
    grid-template-columns: 36% 50%;
  }
`;

const FeatureItem = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  font-size: 18px;
  line-height: 24px;

  ${tablet} {
    width: max-content;
  }
`;
