import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms';

interface ISaleOfferCard {
  title: React.ReactNode;
  infoText: string;
  image: IconsNames;
  path: string;
  children?: React.ReactNode;
}

export const SaleOfferCard = memo(
  ({ title, infoText, image, children, path }: ISaleOfferCard) => {
    const navigate = useNavigate();
    const onCardClick = () => {
      navigate(path, { replace: true });
    };

    return (
      <Wrapper onClick={onCardClick}>
        <Info>{infoText}</Info>
        <Title>{title}</Title>
        {children}
        <IconStyled name={image} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  background-color: ${ColorService.WHITE};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  width: 100%;
  height: 380px;
  cursor: pointer;
  transition: 0.6s;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 8px 20px rgba(100, 100, 100, 0.4);
  }

  ${tablet} {
    height: 332px;
  }
}
`;

const Info = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
  color: ${ColorService.GRAY};
  text-transform: uppercase;
  margin-bottom: 8px;
  z-index: 1;
`;

const Title = styled.div`
  max-width: 324px;
  z-index: 1;
`;

const IconStyled = styled(Icon)`
  height: 78%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  border-radius: 24px;
  right: -40px;
  top: 0;

  ${tablet} {
    height: 100%;
    bottom: 0;
    right: -12px;
  }
`;
