import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily } from 'services';

interface IBtnGoBack {
  title?: string;
}

export const BtnGoBack = memo(({ title = 'Назад' }: IBtnGoBack) => {
  const navigate = useNavigate();

  return (
    <WrapperLink>
      <StyledLink onClick={() => navigate(-1)}>
        <IconArrow>
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.255063 9.75927C0.0278449 9.51807 0.0391813 9.13834 0.280384 8.91112L4.43216 5.00005L0.280384 1.08898C0.039182 0.861765 0.0278457 0.482035 0.255064 0.240833C0.482282 -0.000369469 0.862012 -0.0117058 1.10321 0.215513L5.71861 4.56332C5.83896 4.67669 5.90719 4.83471 5.90719 5.00005C5.90719 5.1654 5.83896 5.32341 5.71861 5.43679L1.10321 9.78459C0.862011 10.0118 0.482281 10.0005 0.255063 9.75927Z"
              fill="#2E2E2E"
            />
          </svg>
        </IconArrow>
        {title}
      </StyledLink>
    </WrapperLink>
  );
});

const WrapperLink = styled.div`
  margin-bottom: 40px;
`;

const StyledLink = styled.button`
  background: ${ColorService.TRANSPARENT};
  border: 1px solid ${ColorService.GRAY};
  box-sizing: border-box;
  border-radius: 8px;
  font-family: ${getFontFamily()};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${ColorService.BLACK};
  vertical-align: sub;
  padding-right: 16px;
  position: relative;
  z-index: 1;
  height: 32px;
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
    border: 1px solid ${ColorService.YELLOW_LEMON};
  }
`;

const IconArrow = styled.div`
  cursor: pointer;
  transform: rotate(180deg);
  height: 16px;
  width: 16px;
  margin-top: 4px;
`;
