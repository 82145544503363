import React, { memo } from 'react';

import styled, { css } from 'styled-components';
import CoverImg from 'assets/images/Cover.png';
import CoverImgTablet from 'assets/images/Cover_tablet.png';
import CoverImgMobile from 'assets/images/Cover_mobile.png';
import CoverCourseImg from 'assets/images/CoverCourse.png';
import CoverCourseImgTablet from 'assets/images/CoverCourse_tablet.png';
import CoverCourseImgMobile from 'assets/images/CoverCourse_mobile.png';

import { Footer, Header } from 'components/molecules';

import { ColorService, tablet, laptop_small, desktop } from 'services';

export interface ICoverTemplate {
  children: React.ReactElement;
  isCoursePage?: boolean;
}

export const CoverTemplate = memo(
  ({ children, isCoursePage = false }: ICoverTemplate) => (
    <Paper>
      <Header />

      <ContentWrapper $isCoursePage={isCoursePage}>
        <Wrapper>{children}</Wrapper>
      </ContentWrapper>

      <Footer />
    </Paper>
  ),
);

export const Paper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.BG_WHITE};
`;

const ContentWrapper = styled.div<{ $isCoursePage: boolean }>`
  ${({ $isCoursePage }) => css`
    width: 100%;
    background-image: url(${$isCoursePage
      ? CoverCourseImgMobile
      : CoverImgMobile});
    background-repeat: no-repeat;
    background-size: contain;

    ${tablet} {
      background-image: url(${$isCoursePage
        ? CoverCourseImgTablet
        : CoverImgTablet});
    }

    ${desktop} {
      background-image: url(${$isCoursePage ? CoverCourseImg : CoverImg});
    }
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 345px);
  max-width: 1110px;
  display: flex;
  margin: 0 auto 64px auto;
  padding: 112px 16px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.TRANSPARENT};

  ${tablet} {
    padding: 152px 40px 0;
    margin-bottom: 80px;
  }

  ${laptop_small} {
    padding: 162px 0 0;
  }
`;
