import React, { memo, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import {
  BtnGoBack,
  CourseFeatures,
  CourseProgram,
  CourseStatistics,
  Icon,
  IconsNames,
  InfoCard,
  InfoCardsTablet,
  StickyCounter,
} from 'components/atoms';
import { MainForm } from 'components/molecules';
import {
  AllCourses,
  CoursesPathToKey,
  DayOfferCourses,
  ICourse,
} from 'context';
import {
  ColorService,
  desktop,
  getFontFamily,
  laptop_small,
  tablet,
} from 'services';

export const Course = memo(() => {
  const { courseName } = useParams();
  const [courseData, setCourseData] = useState<ICourse | null>(null);
  const [isDayOffer, setIsDayOffer] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (courseName) {
      setCourseData(
        AllCourses.find(
          ({ key }) =>
            key ===
            CoursesPathToKey[courseName as keyof typeof CoursesPathToKey],
        ) || null,
      );
    }
  }, [courseName]);

  useEffect(() => {
    if (courseData) {
      setIsDayOffer(DayOfferCourses.includes(courseData.key));
    }
  }, [courseData]);

  return (
    <Container>
      <Content>
        <BtnGoBack />

        {courseData && (
          <>
            <TermsInfoWrapper>
              <IconWrapper>
                <StyledIcon name={courseData.img} />
              </IconWrapper>

              <div>
                {isDayOffer && (
                  <DayOffer>
                    <DayOfferTag>Предложение дня</DayOfferTag>
                    <DayOfferTag>-5%</DayOfferTag>
                  </DayOffer>
                )}
                <EducationForm>
                  {courseData.educationFrom} — {courseData.duration}
                </EducationForm>
                <Start>{courseData.closestStart}</Start>
              </div>
            </TermsInfoWrapper>
            <CourseTitle>{courseData.courseName}</CourseTitle>
            <WillLearn>{courseData.youWillLearn}</WillLearn>

            <CourseFeatures />

            <BlockWrapper>
              <Giveaway>
                <AdditionalDiscountBlock>
                  <DiscountBlockTitle>
                    Оставь заявку и участвуй в розыгрыше
                  </DiscountBlockTitle>
                  <StyledDiscountIcon name={IconsNames.discount50} />
                </AdditionalDiscountBlock>
                <DiscountBlockText>
                  <li>3 сертификата на бесплатное обучение</li>
                  <li>5 сертификатов на обучение со скидкой 50%</li>
                  {/* <li>10 сертификатов на скидку 20 000 ₽ </li> */}
                  <li>Гарантированная скидка 15 000 ₽ для каждого</li>
                </DiscountBlockText>
                <GiveawayText>
                  Воспользоваться скидками можно на курсах, которые стартуют в
                  декабре 2024 и весь 2025 год
                </GiveawayText>
              </Giveaway>
            </BlockWrapper>

            <BlockWrapper>
              <StyledInfoCardsTablet />
            </BlockWrapper>

            <BlockWrapper>
              <BlockTitle>Кем ты станешь</BlockTitle>
              <Text>{courseData.futureKnowledge}</Text>
            </BlockWrapper>

            <BlockWrapper>
              <Salary>
                <SalaryBlockTitle>
                  {courseData.futureSalaryTitle}
                </SalaryBlockTitle>
                <SalaryWrap>
                  <SalaryText>
                    После курса студенты в среднем зарабатывают
                  </SalaryText>
                  <SalaryNumber>{courseData.salary}</SalaryNumber>
                </SalaryWrap>
              </Salary>
            </BlockWrapper>

            <BlockWrapper>
              <ProgramBlockTitle>Программа</ProgramBlockTitle>
              <CourseProgram
                hours={courseData.academyHours}
                text={courseData.programText}
                lessons={courseData.lessons}
              />
            </BlockWrapper>
          </>
        )}

        <CourseStatistics />
      </Content>

      <FormWrapper ref={formRef}>
        <StyledMainForm
          hasDiscountInfo
          isDayOffer={isDayOffer}
          discountPrice={courseData?.discountPrice}
          oldPrice={courseData?.oldPrice}
          perMonth={courseData?.perMonth}
          benefitIcon={courseData?.benefitIcon}
        />
        <StyledInfoCard />
      </FormWrapper>

      <StickyCounter
        formRef={formRef}
        hasDiscountInfo
        discountPrice={courseData?.discountPrice}
        oldPrice={courseData?.oldPrice}
        perMonth={courseData?.perMonth}
        oldPerMonth={courseData?.oldPerMonth}
      />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
  }
`;

const Content = styled.div``;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${desktop} {
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 54px;
  }
`;

const StyledMainForm = styled(MainForm)`
  ${desktop} {
    width: 414px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  display: none;

  ${desktop} {
    display: unset;
    width: 414px;
  }
`;

const TermsInfoWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  align-items: center;

  ${tablet} {
    gap: 24px;
  }
`;

const IconWrapper = styled.div`
  background-color: ${ColorService.WHITE};
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;

const StyledIcon = styled(Icon)`
  height: 74%;
  width: 134%;
  object-fit: contain;
  align-self: flex-end;
  position: absolute;
  bottom: -4px;
  right: -15%;
`;

const DayOffer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const DayOfferTag = styled.div`
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid ${ColorService.YELLOW};
  color: ${ColorService.YELLOW};
  text-transform: uppercase;
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
`;

const EducationForm = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${ColorService.GRAY};
  margin-bottom: 8px;
`;

const Start = styled.p`
  color: ${ColorService.BLACK};
`;

const CourseTitle = styled.h1`
  font-size: 32px;
  line-height: 44px;
  font-family: ${getFontFamily('bold')};
  margin-bottom: 12px;

  ${tablet} {
    font-size: 54px;
    line-height: 62px;
  }
`;

const WillLearn = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
`;

const StyledInfoCardsTablet = styled(InfoCardsTablet)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    flex-direction: row;
  }

  ${desktop} {
    display: none;
  }
`;

const BlockWrapper = styled.div`
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }
`;

const BlockTitle = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-family: ${getFontFamily('bold')};
  margin-bottom: 16px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
`;

const Salary = styled.div`
  background-color: ${ColorService.BG_GRAY};
  border-radius: 24px;
  padding: 32px;

  ${tablet} {
    padding: 40px;
  }
`;

const Giveaway = styled(Salary)`
  background-color: ${ColorService.WHITE};
`;

const SalaryBlockTitle = styled(BlockTitle)`
  margin-bottom: 32px;
  color: ${ColorService.WHITE};
  ${tablet} {
    max-width: 456px;
    margin-bottom: 40px;
  }
  ${desktop} {
    margin-bottom: 48px;
  }
`;

const DiscountBlockTitle = styled(BlockTitle)`
  margin-bottom: 16px;
  ${tablet} {
    margin-bottom: 24px;
  }
`;

const DiscountBlockText = styled.ul`
  font-size: 18px;
  line-height: 24px;
  list-style: initial;
  padding-left: 20px;
  margin-bottom: 32px;

  ${tablet} {
    margin-bottom: 40px;
  }
  li:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const AdditionalDiscountBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 28px;
`;

const SalaryText = styled(Text)`
  max-width: 210px;
  color: ${ColorService.WHITE};
`;

const GiveawayText = styled(Text)`
  color: ${ColorService.GRAY};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const SalaryWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${desktop} {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  ${laptop_small} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const SalaryNumber = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 48px;
  line-height: 54px;
  color: ${ColorService.YELLOW};

  ${tablet} {
    text-align: right;
    max-width: 300px;
    font-size: 54px;
    line-height: 62px;
  }
`;

const ProgramBlockTitle = styled(BlockTitle)`
  margin-bottom: 12px;
`;

const StyledDiscountIcon = styled(Icon)`
  display: none;
  flex-shrink: 0;

  ${tablet} {
    display: block;
    width: 98px;
    height: 88px;
  }
`;
